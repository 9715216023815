/* =============================================================================
   ~LIB custom mixins
   ========================================================================== */
@mixin lib-filter($effect:null, $size:5px) {
  @if ($effect) {
    -moz-filter: $effect($size);
    -o-filter: $effect($size);
    -ms-filter: $effect($size);
    -webkit-filter: $effect($size);
    filter: $effect($size);
  }
}

@mixin lib-bgimage-blur($url:null, $size:5px) {
  @if ($url) {
    background-image: url($url);
    background-size: cover;
    background-attachment: fixed;
  }
  @include mi-filter(blur, $size);
}

@mixin lib-cover-bg($img-uri) {
  background: url($img-uri) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

@mixin lib-cover-bg-image($x_pos: 50%, $y_pos: 0%) {
  background-repeat: no-repeat;
  background-position: $x_pos, $y_pos;
  background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

@mixin lib-translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  -moz-transform: translate3d($x, $y, $z);
  -o-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

@mixin lib-opacity($opacity:1, $opacity_val: round($opacity * 100)) {
  opacity: $opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacity_val + ")";
  filter: alpha(opacity=$opacity_val);
  zoom: 1;
}

@mixin lib-transform-scale($scale:1.25) {
  -webkit-transform: scale($scale); /* Safari and Chrome */
  -moz-transform: scale($scale); /* Firefox */
  -ms-transform: scale($scale); /* IE 9 */
  -o-transform: scale($scale); /* Opera */
  transform: scale($scale);
}

@mixin lib-translate($x:-50%, $y:-50%) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin lib-linear-gradient-bg($from_dir:top, $to_dir:bottom, $from_color:#000, $to_color:#000, $from_opacity:0.4, $to_opacity:0.4) {
  background: -moz-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left $from_dir, left $to_dir, color-stop(0%, rgba($from_color, $from_opacity)), color-stop(100%, rgba($to_color, $to_opacity))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* IE10+ */
  background: linear-gradient(to $to_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from_color}', endColorstr='#{$to_color}', GradientType='0'); /* IE6-9 */
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from_color}', endColorstr='#{$to_color}', gradientType='0')";
}

@mixin lib-linear-gradient-bg-image($from_dir:top, $to_dir:bottom, $from_color:#000, $to_color:#000, $from_opacity:0.5, $to_opacity:0.5) {
  background-image: -moz-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* FF3.6+ */
  background-image: -webkit-gradient(linear, left $from_dir, left $to_dir, color-stop(0%, rgba($from_color, $from_opacity)), color-stop(100%, rgba($to_color, $to_opacity))); /* Chrome,Safari4+ */
  background-image: -webkit-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* Chrome10+,Safari5.1+ */
  background-image: -o-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* Opera 11.10+ */
  background-image: -ms-linear-gradient($from_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* IE10+ */
  background-image: linear-gradient(to $to_dir, rgba($from_color, $from_opacity) 0%, rgba($to_color, $to_opacity) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from_color}', endColorstr='#{$to_color}', GradientType='0'); /* IE6-9 */
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from_color}', endColorstr='#{$to_color}', gradientType='0')";
}

@mixin lib-multi-column($count: 3) {
  -moz-column-count: $count;
  -webkit-column-count: $count;
  -ms-column-count: $count;
  -o-column-count: $count;
  column-count: $count;
}

@mixin lib-multi-gap($width: 2rem) {
  -webkit-column-gap: $width;
  -moz-column-gap: $width;
  -ms-column-gap: $width;
  -o-column-gap: $width;
  column-gap: $width;
}

.gly-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.gly-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.gly-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.gly-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.gly-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}