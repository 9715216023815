// Bring in a few things from Bootstrap so we can use the grid, clearfix and vendor mixins (for transition, etc)
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/_variables";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/mixins/_clearfix";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/mixins/_grid";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_variables";
@import "../../../../../profiles/wcmc/themes/wcmc_bootstrap/scss/_mixins";

@import "mixins";
@import "library_form";
@import "nojs";
@import "software_hub";

/* =============================================================================
   ~Global Elements
   ========================================================================== */
//html{
//  @include lib-translate3d(0, 0, 0);
//}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: $wcm-dark-gray;
}

iframe {
  overflow: hidden;
}

.title-bar {
  padding-bottom: 1.4rem;

  .container {
    line-height: 0.9;

    .site-title {
      font-size: 2rem;
    }
  }
}

/**
 * Navigation
 */
#primary-nav {
  ul {
    li.level-1 {
      width: 15%;
      white-space: nowrap;
      width: auto;
      padding: 1rem 0rem;
      min-width: 14%;
      font-size: 12.5px;

      @include breakpoint($md) {
        font-size: 15px;
      }

      @include breakpoint($lg) {
        font-size: 16px;
      }

      .active-trail {
        color: #B31B1B;
      }
    }

    li:nth-child(1),
    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(5) {
      width: 18%;
    }
  }
}

#edit-view-type-wrapper {
  .select2 {
    #select2-edit-view-type-container {
      background-repeat: no-repeat;
      background-position: 4% center;
      padding-left: 12px;
    }

    .results-icon-list {
      background-image: url("../images/list.png");
    }

    .results-icon-detail {
      background-image: url("../images/detail.png");
    }
  }
}

#edit-view-type-wrapper {
  .form-item-view-type {
    .select2 {
      font-size: 1.2rem !important;
      width: 10.4rem !important;

      .select2-selection--single {
        height: 2.7rem;
        border: solid 1px #ddd;
        width: 100%;

        .select2-selection__rendered {
          line-height: 2;
          font-size: 13px;
        }

        .select2-selection__arrow {
          width: 1.4rem;
          height: 100%;
          display: block;
          position: absolute;
          top: -1px;
          font-size: 13px;
          font-weight: 600;
          text-align: center;
          line-height: 2.2;
          left: 10.8rem;
          border: none;
        }
      }
    }
  }
}

.select2-results {
  font-size: 12px !important;
}

#select2-edit-view-type-results {
  li:first-child {
    background-image: url("../images/list.png");
    background-repeat: no-repeat;
    background-position: left center;
  }

  li:last-child {
    background-image: url("../images/detail.png");
    background-repeat: no-repeat;
    background-position: left center;
  }
}

.views-exposed-form {
  .views-exposed-widget {
    padding-top: 0;
  }
}

/* =============================================================================
   ~Base Elements
   ========================================================================== */
.panel-hero-3col,
.panel-fullhero-3col,
.panel-mid-twocol,
.panel-twocol {
  .main-content {
    margin-bottom: 3rem;

    .panel-pane {
      >div {

        >h2:first-child,
        >h3:first-child,
        >h4:first-child,
        >h5:first-child,
        >h6:first-child {
          margin-top: 0;
        }
      }

      .view-staff {
        .views-row-first {

          h2:first-child,
          h3:first-child,
          h4:first-child,
          h5:first-child,
          h6:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  a {
    color: $wcm-bright-orange;
  }
}

#main {
  padding: 2.2rem 0.2rem 0 0.2rem;

  @include breakpoint($md) {
    padding: 2.2rem 0 0 0;
  }
}

header {
  .brand__nav .container {
    ul {
      li.wcmc-library-hour-divider {
        position: relative;
        display: block;
        padding: 10px 0px;
        color: $wcm-white;
        line-height: 2.5;
      }

      li.wcmc-library-hour-status {
        position: relative;
        display: block;
        padding: 10px 15px;
        color: $wcm-white;
        line-height: 2.5;
      }
    }
  }

  .brand__lockup .container {
    position: relative;
    top: 0.4rem;

    img {
      height: 76px;
    }
  }
}

footer {
  padding: 0;

  .footer-contact {
    .footer-contact__site-name a {
      font-size: 15px;

      @include breakpoint($md) {
        font-size: 18px;
      }
    }
  }

  .footer-site {
    .footer-address {
      border-right: none;

      @include breakpoint($md) {
        width: 22% !important;
      }
    }

    .footer-nav {
      margin-bottom: 2rem;
      border-left: 1px solid #DDD;

      @include breakpoint($md) {
        width: 78% !important;
      }

      .block {
        @include lib-multi-column;
        @include lib-multi-gap;

        ul.menu {
          width: 100%;

          li:nth-child(3),
          li:nth-child(4) {
            display: inline-block;
          }
        }
      }
    }
  }
}

// library homepage layout
.panel-library-home {

  a:hover,
  a:focus {
    color: $wcm-bright-orange;
    text-decoration: none;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  a {
    color: $wcm-black;
    text-decoration: none;

    .glyphicon-play {
      color: $wcm-bright-orange;
      font-size: 10px;
      padding-top: 0px;
      padding-left: 3px;
      top: 0;
    }
  }

  .panel-pane {
    .pane-title {
      font-size: 2.2rem;
    }
  }

  .about-this-search {
    a {
      color: $wcm-white;
    }

    .tooltip.top .tooltip-inner {
      background-color: $wcm-white;
      color: $wcm-black;
    }

    .tooltip.top .tooltip-arrow {
      border-top-color: $wcm-white;
      color: $wcm-black;
    }
  }

  .library-home-header {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.95;
    background-color: $wcm-black;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.6;
      background-image: url("../images/library-stairs-homepage.jpg");
      @include lib-cover-bg-image();
      //@include lib-linear-gradient-bg-image(top, bottom, $wcm-black, $wcm-black, 0.5, 0.5);
      z-index: -1;
      will-change: transform;
    }
  }

  #library-home-header-right {
    .view-display-id-panel_pane_6 {
      padding: 0 0 2rem 0;

      @include breakpoint($md) {
        padding: 3rem 0px;
      }

      .view-content {
        background: #F5F5F5 none repeat scroll 0% 0%;
        padding: 0.5rem 1.4rem;
        border: 2px solid $wcm-bright-orange;
        border-radius: 4px;
        min-height: 22rem;

        @include breakpoint($md) {
          min-height: 33rem;
        }

        .featured-news-title {
          font-family: $wcm-regular;
          font-size: 2rem;
          line-height: 1.3;
        }

        .featured-news-text {
          font-size: 1.6rem;
          padding: 0.6rem 0.2rem;
        }

        .views-field-nothing {
          margin-bottom: 0.5rem;

          @include breakpoint($lg) {
            margin-top: 1.2rem;
          }

          .views-more-link {
            font-size: 1.6rem;
          }
        }
      }

      a {
        color: $wcm-bright-orange;
      }
    }
  }

  #library-home-left {
    .view-popular-list {
      .views-field-title {
        border-bottom: 1px solid #ddd;
        margin: 0.8rem 0px;
        padding: 0.8rem 0px;
        font-family: $wcm-regular;

        a {
          font-size: 1.5rem;
          color: #3d3d3d;

          @include breakpoint($lg) {
            font-size: 1.8rem;
          }

          .glyphicon {
            float: right;
            top: 6px;
            color: $wcm-red;
          }

          &:hover {
            color: $wcm-bright-orange;
          }
        }
      }

      &:after {
        @include clearfix;
      }
    }
  }

  #library-home-center {
    margin-bottom: 2rem;

    .teaser-title {
      .date-display-range {
        display: inline;
      }
    }

    .views-field-nothing {
      .read-more {
        color: $wcm-bright-orange;
      }
    }
  }

  .info-row-1-wrapper {
    background: #ddd;

    .pane-title {
      margin: 3rem 0 3.5rem 0;
      text-align: center;
    }

    .view-display-id-panel_pane_1 {
      text-align: center;

      ul {
        display: flex;
        flex-wrap: wrap;
        max-width: 1024px;
        margin: 0 auto;
        $grid-gap: 2.8rem;
        column-gap: $grid-gap;
        row-gap: $grid-gap;

        li {
          list-style: none;
          margin: 0;
          width: 100%;

          @media (min-width: 480px) {
            width: calc(50% - #{$grid-gap} * 1/2);
          }

          @include breakpoint($md) {
            width: calc(25% - #{$grid-gap} * 3/4);
          }

          .views-field-field-ebook-cover {
            padding: 2rem;
            max-width: 180px;
            margin: 0 auto;
            border-radius: 3px;
            background: #fff;
            box-shadow: 0px 0px 45px -13px rgba(0, 0, 0, 0.75);

            >div {
              position: relative;

              &:after {
                content: "";
                display: block;
                padding-bottom: 150%;
              }

              img {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
              }
            }
          }

          .views-field-title {
            margin-top: 3rem;

            a {
              font-size: 1.8rem;
              font-weight: 600;
              color: $wcm-black;

              &:hover {
                color: $wcm-bright-orange;
                text-decoration: none;
              }
            }
          }

          .views-field-field-ebook-publish-year,
          .views-field-field-ebook-author {
            font-size: 1.4rem;
            text-transform: uppercase;
          }
        }
      }

      .view-footer {
        text-align: center;
        margin: 2.7rem 0 2rem;

        a {
          color: $wcm-bright-orange;
        }
      }
    }
  }

  .info-row-2-wrapper {
    background-color: #f7f7f7;

    .pane-general-info-slides {
      position: relative;
      text-align: center;

      .pane-title {
        margin-top: 4rem;
        margin-bottom: 3rem;
        text-align: center;
      }

      #general-info-slideshow {
        z-index: 0;
        width: 84.5% !important;
        display: inline-block;
        min-height: 28rem;
        background-color: #f7f7f7;

        @include breakpoint($sm) {
          width: 78.5% !important;
        }

        @include breakpoint($md) {
          width: 91.5% !important;
        }

        @include breakpoint($lg) {
          width: 99.5% !important;
        }

        .cycle-carousel-wrap {
          background-color: #f7f7f7;

          .info-slideshow-item {
            padding: 0 0rem 0 1.4rem;
            margin: 0 1.5rem 0 2.8rem;
            text-align: center;
            vertical-align: top;

            .info-slide-title {
              font-size: 1.8rem;
              font-weight: 600;
              //color: $wcm-black;
              max-width: 22rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .info-slide-image {
              margin: 0;
              padding: 0;

              >img {
                width: 22rem;
                max-height: 14rem;
                border: 0;
                border-radius: 0;
              }
            }

            .info-slide-text {
              text-align: center;
              max-width: 22rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .info-slide-title>p,
            .info-slide-text>p {
              text-align: center;
              color: $wcm-black;
              padding: 0;
              margin: 0;
            }
          }
        }
      }

      #general-info-control {
        z-index: 10;

        .cycle-prev {
          position: absolute;
          display: block;
          background-position: 0% 50% !important;
          height: 5rem;
          width: 3.1rem;
          top: 10.2rem;
          opacity: 0.3;
          background: url("../images/arrow-left.png") no-repeat;
          background-size: 4.5rem;
        }

        .cycle-next {
          position: absolute;
          display: block;
          background-position: 100% 50% !important;
          height: 5rem;
          width: 3.1rem;
          top: 10.2rem;
          right: 0;
          opacity: 0.3;
          background: url("../images/arrow-right.png") no-repeat;
          background-size: 4.5rem;
        }

        .disabled {
          //display: none;
        }
      }
    }
  }

  .info-row-3-wrapper {
    position: relative;
    background: #ddd;
    width: 100%;
    height: auto;
    min-height: 33rem;
    z-index: 1;
    opacity: 0.95;
    overflow: hidden;
    @include lib-cover-bg-image(50% 24%);

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.8;
      //background-image: url("../images/art-show-homepage.jpg");
      //@include lib-cover-bg-image(50% 24%);
      @include lib-linear-gradient-bg-image(top, bottom, $wcm-black, $wcm-black, 0.2, 0.2);
      z-index: -1;
      will-change: transform;
    }

    .panel-pane {
      color: $wcm-white;
      opacity: 0.95;

      a {
        color: $wcm-white;

        &:hover {
          color: $wcm-bright-orange;
        }
      }

      .pane-title {
        margin-top: 6rem;
        color: $wcm-white;
        padding: 1rem 0;
        border-bottom: 4px solid $wcm-red;
        width: 6rem;
      }

      .teaser-title {
        padding-bottom: 6rem;
        font-size: 3.6rem;
      }

      .views-field {
        font-size: 1.6rem;
      }

      .views-field-nothing {
        float: right;
        margin-bottom: 3rem;
      }
    }
  }

  .info-row-4-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 33rem;
    z-index: 1;
    opacity: 0.95;
    overflow: hidden;
    background-color: $wcm-black;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      background-image: url("../images/medical-archives-home.jpg");
      @include lib-cover-bg-image(50% 48%);
      //@include lib-linear-gradient-bg-image(top, bottom, $wcm-black, $wcm-black, 0.2, 0.5);
      z-index: -1;
      will-change: transform;
    }

    .panel-pane {
      padding: 5rem 0 6rem 0;
      color: $wcm-white;
      opacity: 0.95;

      .pane-title {
        margin-top: 2rem;
        border-bottom: 4px solid $wcm-red;
        padding: 0.8rem 0;
        width: 64%;
        font-size: 2.8rem;

        @include breakpoint($sm) {
          width: 54%;
          font-size: 3rem;
        }

        @include breakpoint($md) {
          width: 44%;
          font-size: 3.2rem;
        }

        @include breakpoint($lg) {
          width: 40%;
          font-size: 3.6rem;
        }

        >a {
          color: $wcm-white;

          &:hover {
            color: $wcm-bright-orange;
          }

          &:after {
            display: none;
          }
        }
      }

      .archive-body {
        font-size: 2.6rem;
      }

      .overlay-wrapper {
        display: block;
        padding-top: 6rem;

        .about-overlay {
          float: left;
        }

        .more {
          float: right;

          a {
            color: $wcm-white;

            &:hover {
              color: $wcm-bright-orange;
            }
          }
        }
      }
    }
  }

  .info-row-5-wrapper {
    //margin-bottom: -2rem;
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
    opacity: 0.95;
    background-color: #f2f2f2;
    ;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.05;
      background-image: url("../images/ariel_view.jpg");
      @include lib-cover-bg-image();
      z-index: -1;
      will-change: transform;
      // @include lib-linear-gradient-bg-image(top, bottom, #f5f5f5, #f5f5f5, 0.95, 0.95);
    }

    .panel-pane {
      z-index: 2;
      opacity: 0.95;

      .pane-title {
        margin: 2.6rem 0px;

        @include breakpoint($md) {
          margin: 4.6rem 0px 3.6rem 0;
        }
      }

      .view-popular-list {
        margin-bottom: 3rem;

        li {
          display: inline-block;
          margin: 1rem 1.5rem 1.6rem 0;

          .field-content {
            a {
              color: #9c9c9c;
              background: $wcm-white;
              text-align: center;
              padding: 1rem 2.5rem;
              opacity: 1;
              border-radius: 5px;
              border: 1px solid $wcm-white;

              &:hover {
                background: $wcm-bright-orange;
                color: $wcm-white;
              }

              &:after {
                display: inline;
              }
            }
          }
        }

        .view-footer {
          margin: 2rem 0 3rem 0;

          a {
            color: $wcm-bright-orange;
          }
        }
      }
    }
  }

  #homepage-sidebar-pane {
    .homepage-sidebar-list {
      position: relative;
      display: block;
      width: 100%;
      height: 110px;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .wcm-cta__tile {
        width: 100%;
        height: 14rem;
        clear: both;
        border: none;

        span {
          color: #808080;
        }

        .wcm-cta__detail {
          width: 100%;
          min-height: 14rem;
          font-size: 14px;
          line-height: 1.4;
          border-bottom: 1px solid #ddd;
          padding: 2rem 0;

          .homepage-sidebar-list-title {
            font-size: 1.5rem;
            text-transform: uppercase;
            font-weight: 600;
            color: $wcm-black;
          }
        }
      }
    }
  }
}

.panel-fullhero-3col {
  .hero-section {
    .pane-node-field-archives-featured-image {
      .pane-title {
        position: absolute;
        color: $wcm-white;
        top: 25%;
        font-size: 2.8rem;
        left: 4%;
        z-index: 2;

        @include breakpoint($sm) {
          font-size: 3.2rem;
          left: 8%;
          top: 40%;
        }

        @include breakpoint($md) {
          font-size: 4rem;
          top: 38%;
        }

        @include breakpoint($lg) {
          left: 12%;
        }
      }

      >div {
        z-index: 2;
        max-height: 32rem;
        overflow: hidden;
        vertical-align: middle;
        background-color: $wcm-black;
        opacity: 0.95;

        img {
          position: relative;
          display: block;
          margin: 0;
          opacity: 0.6;
          z-index: -1;
        }
      }
    }
  }

  #main-content {
    position: relative;

    .view-archives-blog-featured {
      margin-bottom: 2rem;

      .views-field-created {
        margin-bottom: 2rem;
      }

      .views-field-field-blog-featured-image {
        @include breakpoint($md) {
          width: 24%;
          margin-right: 2rem;
          display: inline-block;
        }

        +.views-field-nothing {
          @include breakpoint($md) {
            width: 72%;
          }
        }
      }

      .views-field-nothing {
        float: right;

        h2 {
          margin-top: -1.2rem;
        }

        .field-content {
          p {
            padding-bottom: 1rem;
          }
        }

        .btn--wcm {
          display: inline-block;
          width: 12rem;
          font-size: 13px;
        }
      }

      .item-list {
        clear: both;
      }
    }

    .view-display-id-panel_pane_4 {
      margin-top: 8rem;
    }
  }
}

// temp begins
.departmental-timeline-list,
.view-display-id-panel_pane_4 {
  .item-list {
    text-align: center;

    @include breakpoint($md) {
      width: 94.9%;
    }

    @include breakpoint($lg) {
      width: 78%;
    }

    ul {
      list-style: none;
      text-align: left;
      font-size: 0;
      width: 33rem;

      @include breakpoint($md) {
        width: 66rem;
      }

      li {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        background: $wcm-white;
        width: 33rem;
        text-align: left;

        .views-field-field-archives-link-image {
          width: 100%;

          a {
            z-index: 3;

            .archives-title {
              position: absolute;
              font-size: 2.4rem;
              font-weight: 600;
              max-width: 24rem;
              padding-left: 1.6rem;
              bottom: 1rem;
              line-height: 1.5;
              color: $wcm-white;
              opacity: 0.9;
              z-index: 3;
              pointer-events: none;
            }

            .archives-image {
              padding: 0;
              margin: 0;
              z-index: 1;

              &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: inline-block;
                @include lib-linear-gradient-bg(top, bottom, $wcm-black, $wcm-black, 0.15, 0.15);
                overflow: hidden;
                z-index: 1;
                pointer-events: none;
              }

              img {
                width: 33rem;
                height: 100%;
              }

              .archives-body-rollover {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.55);
                padding: 2.6rem 1.5rem 6.5rem 1.5rem;
                opacity: 0;
                -webkit-transition: opacity .25s ease;
                -moz-transition: opacity .25s ease;
                width: 33rem;
                height: 100%;
                overflow: hidden;
                color: #ddd;
                font-size: 14px;
                z-index: 2;
              }

              .archives-body-rollover-light {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.35);
                padding: 2.6rem 1.5rem 6.5rem 1.5rem;
                opacity: 0;
                -webkit-transition: opacity .25s ease;
                -moz-transition: opacity .25s ease;
                width: 33rem;
                height: 100%;
                overflow: hidden;
                color: #ddd;
                font-size: 14px;
                z-index: 2;
              }
            }

            &:hover {
              .archives-body-rollover {
                opacity: 1;
                pointer-events: none;
              }

              .archives-body-rollover-light {
                opacity: 1;
                pointer-events: none;
              }
            }
          }

          p {
            margin: 0;
          }
        }
      }

      &:after {
        content: '';
        clear: both;
      }
    }
  }
}

// temp ends

.panel-hero-3col {
  .news-announcement-title {
    h1 {
      margin-bottom: 2rem;
    }
  }

  #main-content {

    // panel pane custom style start
    .pane-node-field-blog-featured-image,
    .pane-node-field-featured-image {
      float: left;
      padding-right: 2.4rem;

      img {
        width: auto;
        margin: 5px 0px 20px 0px;
      }
    }

    .pane-custom {
      span {
        .glyphicon {
          color: $wcm-red;
          font-size: 1.8rem;
          padding-bottom: 0.5rem;
          margin-right: 0.5rem;
        }
      }
    }

    // panel pane custom style end
    .view-id-archives_timeline {
      .simple_timeline {
        ul.archives-timeline {
          background: none;

          &:after {
            @include breakpoint($md) {
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              bottom: 0;
              left: 50%;
              border-left: 1px solid #ecebe8;
            }
          }

          li {
            margin-bottom: 3rem;
            max-height: 17.5rem;
            width: 100%;
            margin: 0;

            @include breakpoint($md) {
              width: 50%;
              margin: 0 0 1.8em 1.5em;
            }

            @include breakpoint($lg) {
              margin: 0 0 2.5em 1.5em;
            }

            .timeline-content {
              background: #efefef;
              padding: 1rem;
              min-height: 7.4rem;
              border-radius: 4px;
              border: 1px solid #eee;

              .timeline-image {
                display: block;
                text-align: center;
                clear: both;
                float: initial;
                padding-bottom: 0.4rem;

                >img {
                  width: 100%;

                  @include breakpoint($md) {
                    width: auto;
                  }
                }
              }

              .timeline-text {
                text-align: left !important;
                padding: 0;
                margin: 0;
                font-size: 1.4rem;
                //line-height: 1.6;
                line-height: 22px;
                width: 100%;
                max-width: 100%;

                @include breakpoint($md) {
                  max-width: 32rem;
                }

                >p {
                  margin: 0;
                }
              }
            }

            &:last-child {
              @include breakpoint($md) {
                max-height: 23.5rem;
              }
            }
          }

          li:nth-child(even) {
            float: none;

            @include breakpoint($md) {
              float: right;
            }

            >div {
              position: relative;
              background: none;
              min-width: 100%;
              margin: 0;
              padding-left: 0px;

              @include breakpoint($md) {
                min-width: 48rem;
                margin-left: -10px;
                top: -10px;
              }
            }

            .timeline-content {
              max-width: 100%;
              min-width: 100%;
              padding: 2rem;
              margin-bottom: 2rem;

              @include breakpoint($md) {
                margin: -1.2% 25.8%;
                max-width: 55%;
                min-width: 55%;
                padding: 1rem;
                margin-bottom: 0;
              }

              @include breakpoint($lg) {
                margin: -1.2% 9.8%;
                max-width: 71%;
                min-width: 71%;
              }

              &:after {
                @include breakpoint($md) {
                  content: "";
                  border-color: transparent #efefef transparent transparent;
                  border-style: solid;
                  border-width: 12px;
                  width: 0;
                  height: 0;
                  position: absolute;
                  top: 4px;
                  left: 68px;
                }
              }

              &:after {
                @include clearfix();
              }
            }

            .timeline-date {
              font-size: 1.5rem;
              color: $wcm-black;
              padding: 1rem 2rem 0.4rem;
              background: #fbfbfb;
              border: 1px solid #eee;
              border-radius: 3px;
              width: 14rem;
              text-align: center;

              @include breakpoint($md) {
                position: absolute;
                left: -6rem;
                top: 0;
              }
            }
          }

          li:nth-child(odd) {
            float: none;

            @include breakpoint($md) {
              float: left;
            }

            >div {
              position: relative;
              background: none;
              min-width: 100%;
              float: none;
              margin: 0;
              padding-right: 0;

              @include breakpoint($md) {
                float: right;
                margin-right: -10px;
                min-width: 48rem;
                top: 10px;
              }
            }

            .timeline-content {
              max-width: 100%;
              min-width: 100%;
              padding: 2rem;
              margin-bottom: 2rem;

              @include breakpoint($md) {
                margin: -2.2% 21.4%;
                max-width: 55%;
                min-width: 55%;
                padding: 1rem;
                margin-bottom: 0;
              }

              @include breakpoint($lg) {
                margin: -2.2% 5.4%;
                max-width: 71%;
                min-width: 71%;
              }

              &:after {
                @include breakpoint($md) {
                  content: "";
                  border-color: transparent transparent transparent #efefef;
                  border-style: solid;
                  border-width: 12px;
                  width: 0;
                  height: 0;
                  position: absolute;
                  top: 4px;
                  right: 90px;
                }
              }
            }

            .timeline-date {
              font-size: 1.5rem;
              color: $wcm-black;
              padding: 1rem 2rem 0.4rem;
              background: #fbfbfb;
              border: 1px solid #eee;
              border-radius: 3px;
              width: 14rem;
              text-align: center;

              @include breakpoint($md) {
                position: absolute;
                right: -3.8rem;
                top: 0;
              }
            }
          }

          li:last-child {
            margin-bottom: 8rem;
          }
        }
      }
    }

    .pane-profiles-panel-pane-2 {
      .view-content {
        .views-row {
          padding-top: 1.6rem;
          padding-bottom: 0;
          clear: both;
          display: inline-block;
          width: 100%;

          @include breakpoint($md) {
            padding-bottom: 2rem;
          }

          .profile-list-image {
            float: none;

            @include breakpoint($md) {
              float: left;
              width: 15%;
            }

            img {
              width: 12rem;
              height: auto;
              max-width: 12rem;
              vertical-align: bottom;
            }
          }

          .views-field-nothing {
            float: none;
            margin-top: 2rem;

            @include breakpoint($md) {
              float: right;
              width: 80%;
              margin-top: 0;
            }

            @include breakpoint($lg) {
              width: 83%;
            }

            h5 {
              margin: 0;
            }

            a {
              color: $wcm-dark-orange;
            }
          }

          &:not(:last-child) {
            border-bottom: 1px solid #ddd;
          }
        }
      }
    }

    .view-id-news,
    .view-id-popular_list,
    .view-id-archives_blog {
      .view-content {
        .views-row {
          padding: 2.2rem 0rem;
          clear: both;
          width: 100%;
          min-height: 20rem;

          .views-field-field-popular-list-image,
          .views-field-field-featured-image {
            float: none;
            width: 100%;
            margin-bottom: 1rem;

            img {
              max-width: 18rem;
              height: auto;
              vertical-align: bottom;
              margin-bottom: 2rem;
            }

            @include breakpoint($md) {
              margin-bottom: 0;
              width: 27%;
              float: left;
            }

            @include breakpoint($lg) {
              margin-bottom: 0;
              width: 24%;
              float: left;
            }
          }

          .views-field-nothing {
            float: none;

            @include breakpoint($md) {
              float: left;
              width: 70%;
              margin-right: 2rem;
            }

            @include breakpoint($lg) {
              margin-right: 0;
              width: 75%;
            }

            p {
              padding: 0;
              margin: 0;
            }

            p.custom-text-title {
              color: $wcm-med-gray;
            }

            a {
              font-weight: 600;
              color: $wcm-dark-gray;
              font-size: 1.6rem;

              >.glyphicon {
                color: $wcm-bright-orange;
                font-size: 10px;
                padding-bottom: 8px;
                padding-left: 4px;
                top: -1px;
              }
            }

            .news-term-tag {
              color: $wcm-red;
              font-weight: 400;
              font-size: 1.5rem;
            }
          }
        }
      }

      .item-list {
        clear: both;
      }
    }

    .view-id-news {
      .view-content {
        .views-row {
          min-height: 20.5rem;

          &:not(:first-child) {
            border-top: 1px solid #ddd;
            margin: 2rem 0;
          }
        }
      }
    }

    .view-id-archives_blog,
    .view-id-popular_list {
      .view-content {
        .views-row {
          &:not(:first-child) {
            border-top: 1px solid #ddd;
            margin: 1rem 0;
          }
        }
      }
    }

    .view-id-archives_blog {
      .view-content {
        .views-row {
          min-height: 21.5rem;
        }
      }
    }

    .research-support-col {
      padding: 0;

      ul {
        list-style: none;
        padding: 1rem 0;

        li {
          max-width: 84%;
          margin: 1rem 0;
          border-bottom: 1px solid #ddd;
          padding-bottom: 1rem;

          a {
            color: $wcm-black;
            font-size: 1.8rem;

            .glyphicon {
              float: right;
              color: $wcm-red;
              top: 8px;
            }
          }
        }
      }
    }

    .view-archives-medical-center {
      .views-row {
        clear: both;
        margin-bottom: 1rem;
        height: auto;
        min-height: 20rem;

        .views-field-field-medical-center-date {
          font-weight: 600;

          .field-content {
            display: inline-block;
          }
        }

        .views-field-field-medical-center-image {
          float: none;
          width: 100%;
          margin: 0.6rem 1.2rem 0px 0px;

          @include breakpoint($md) {
            float: left;
            width: 23%;
          }
        }

        .views-field-body {
          .caption-text {
            font-style: italic;
            margin: 1rem;
          }
        }
      }
    }

    // history of medical center
    .pane-archives-medical-center-panel-pane-3 {
      .views-field-title {
        >h3:first-child {
          margin-top: 0;
        }
      }

      .item-list {
        .pagination {
          width: 100%;

          .pager-previous,
          .pager-next {
            a {
              display: block;
              width: 92px;
              margin: 0px auto;
              border-radius: 20px;
              border-width: 4px;
              font-size: 13px;
              text-align: center;
              color: $wcm-dark-orange;
              background-color: $wcm-white;
              border-color: $wcm-bright-orange;

              &:hover {
                color: $wcm-white;
                background-color: $wcm-bright-orange;
                border-color: $wcm-dark-orange;
              }
            }
          }

          .pager-previous {
            float: left;
            margin: 0;
          }

          .pager-next {
            margin: 0;
            float: right;
          }

          .pager-current {
            display: none;
          }
        }
      }
    }

    .view-id-faq {
      .view-content {

        h3,
        h4 {
          margin-bottom: 1rem;
        }
      }

      .view-filters {
        .select2 {
          width: 40rem;
        }
      }
    }

    .pane-personal-papers-panel-pane-1 {
      .node-personal-papers {
        margin-bottom: 1.5rem;
      }
    }

    .pane-personal-papers-panel-pane-2 {
      margin-bottom: 1.2rem;
    }

    .pane-archives-blog-panel-pane-5 {
      .view-filters {
        form {
          .views-submit-button {
            margin: -2.4rem 0 1rem 0;
          }
        }
      }

      .view-header {
        border: 1px solid #ddd;
        background-color: #F6F6F2;
        border-color: #eee;
        border-radius: 6px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        padding: 4px 6px;
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .view-content {
        .views-row {
          min-height: 8rem;
          padding: 0;

          .views-field-path {
            a {
              color: #888;
              font-size: 0.85em;
            }
          }
        }
      }
    }

    // ebooks detail page
    .pane-node-field-ebook-cover {
      margin-right: 2rem;
      margin-bottom: 2rem;

      @include breakpoint($md) {
        float: left;
      }

      img {
        max-width: 18rem;
      }
    }

    .pane-node-field-ebook-link,
    .pane-node-field-ebook-publish-year,
    .pane-node-field-ebook-publisher,
    .pane-node-field-ebook-author {
      .field-label {
        display: inline;
        font-weight: 600;

        a {
          font-size: 1.8rem;
        }
      }
    }

    .pane-node-field-ebook-publish-year {
      margin-bottom: 1rem;
    }

    .pane-wcmc-library-query-panel-pane-4 {
      .views-row {

        .views-field-link,
        .views-field-short-wrapper {
          margin-bottom: 1.8rem;
        }

        .hidden {
          display: block !important;
          visibility: inherit !important;
        }

        .e-resource-details-wrapper {
          .e-resource-details {
            p {
              >span:first-child {
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .fieldable-panels-pane {
      .a-to-z-bar-all {
        float: left;
      }
    }
  }

  #wcmc-library-ask-us {

    ul.ask-us-page-list-full,
    ul.ask-us-page-list {
      list-style: none;
      padding: 0;

      >li {
        padding: 1.2rem 0;
        position: relative;

        .wcm-cta__ask-us {
          position: inherit;
          width: 80%;
          font-size: 12px;
          line-height: 16px;
          left: 2rem;
          top: 0;
          @include lib-translate(-50%, -50%);

          .cta-icon {
            width: 40px;
            height: 40px;
            fill: $wcm-bright-orange;
            color: $wcm-dark-orange;
          }

          .ask-us-item-title {
            position: absolute;
            font-size: 1.6rem;
            font-weight: 600;
            color: $wcm-dark-gray;
            width: 20rem;
            vertical-align: top;
            margin: 1rem 0 1rem 1rem;
          }
        }

        ul {
          list-style-type: disc;
        }
      }

      .btn--wcm {
        display: inline-block;
      }
    }

    ul.ask-us-page-list {
      //@include breakpoint($sm) {
      //  columns: 2;
      //  -webkit-columns: 2;
      //  -moz-columns: 2;
      //}
      display: inline-block;
      vertical-align: top;
      width: 100%;

      @include breakpoint($md) {
        width: 49%;
      }

      >li {
        padding-bottom: 2.2rem;
        //&:nth-child(5) {
        //  display: inline-block;
        //}
      }
    }

    ul.ask-us-page-list-full {
      iframe {
        width: 100%;
      }
    }
  }
}

.panel-mid-twocol {
  #e-resource-filter-pane {
    position: relative;

    #e-resources-filter-form {
      display: inline-block;

      @include breakpoint($md) {
        position: absolute;
        top: 6rem;
        right: 0px;
      }

      .e-resource-toggle-content {
        .chosen-container {
          width: 255px !important;
        }
      }
    }
  }

  #e-resources-alpha-pane {
    display: inline;

    .e-resources-search-form {
      display: inline-block;
      width: 100%;
      margin: 1.5rem 0 -2rem 0;

      .form-item-keyword {
        float: left;
        width: 100%;
        background: #efefef;
        padding: 1.2rem;

        #edit-keyword {
          min-height: 4.8rem;
          min-width: 100%;
          border-radius: 4px;
        }
      }

      #edit-submit {
        display: none;
      }

      :after {
        clear: both;
      }
    }

    .e-resources-alpha-search-wrapper {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #ddd;

      @include breakpoint($md) {
        white-space: nowrap;
      }

      a.e-resources-alpha-search {
        padding: 0 1%;
        color: $wcm-red;
        font-size: 16px;
      }

      span.e-resources-alpha-search {
        padding: 0 1%;
        font-size: 16px;
      }
    }

    #e-resources-search-keyword {
      display: inline-block;
      margin: 2.2rem 0 0.8rem;

      .e-resources-keyword {
        float: left;
      }

      .e-resources-tags {
        float: left;
        padding: 14px 20px;
        margin: 0;

        li.e-resources-tags-all {
          display: inline-block;
          height: 24px;
          line-height: 2;
          position: relative;
          margin: 0 16px 8px 0;
          padding: 0 0px 0 12px;
          ;
          background: #fafbfc;
          -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          color: $wcm-black;
          font-size: 12px;
          text-decoration: none;
          font-weight: bold;
          min-width: 4rem;

          &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            top: 3px;
            right: -9px;
            -webkit-box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.1);
            box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.1);
            background: #fafbfc;
            -webkit-transform: translateY(-15px) rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        li.e-resources-tags-item {
          display: inline-block;
          height: 24px;
          line-height: 2;
          position: relative;
          margin: 0 16px 8px 0;
          padding: 0 14px;
          background: #fafbfc;
          -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
          color: $wcm-black;
          font-size: 12px;
          text-decoration: none;
          font-weight: bold;
          min-width: 4rem;
          cursor: pointer;
          cursor: hand;

          &:after {
            content: "x";
            position: absolute;
            top: 0px;
            right: 0px;
            float: right;
            width: 5px;
            height: 5px;
            color: $wcm-red;
          }

          &:before {
            content: "";
            position: absolute;
            width: 18px;
            height: 18px;
            top: 3px;
            right: -9px;
            -webkit-box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.1);
            box-shadow: 1px -1px 0 0 rgba(0, 0, 0, 0.1);
            background: #fafbfc;
            -webkit-transform: translateY(-15px) rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }

      &:after {
        @include clearfix;
      }
    }
  }

  article#node-821 {
    margin: -2rem 0;

    .submitted {
      display: none;
    }

    h2 {
      display: none;
    }

    form {
      margin-top: 2rem;

      .form-item {
        float: left;
      }

      .form-actions {
        float: left;
        position: relative;
        bottom: 1.4rem;
        margin-left: 0.6rem;
      }
    }
  }

  .e-resources-top-text {
    +ul {
      list-style: none;
      padding: 0;

      li {
        display: inline;
      }

      li+li::before {
        content: ' | ';
      }
    }
  }

  .view-display-id-panel_pane_1,
  .view-display-id-panel_pane_2,
  .view-display-id-panel_pane_3 {
    .search-result-pane-header {
      width: 100%;
      display: inline-block;
      border-bottom: 1px solid #ddd;

      .item-list {
        float: left;

        @include breakpoint($md) {
          width: 48%;
        }

        @include breakpoint($lg) {
          width: 58%;
        }
      }

      .view-filters {
        float: left;

        @include breakpoint($md) {
          width: 30%;
        }

        .views-exposed-form .views-exposed-widget {
          padding: 0;
        }

        #edit-submit-wcmc-library-query {
          display: none;
        }
      }

      .view-header {
        float: right;

        @include breakpoint($md) {
          width: 10%;
        }

        font-size: 1.3rem;
        text-align: right;
        margin-top: 0.4rem;
      }

      .pagination {
        font-size: 1.3rem;
        margin-top: 0.4rem;

        >li {
          margin: 0;
          padding: 0;

          >a,
          >span {
            border: none;
            padding: 0px 8px;
            font-weight: normal;
          }
        }
      }

      .views-widget-per-page {
        .form-item-items-per-page {
          label {
            left: 9rem;
            position: relative;
            font-weight: normal;
          }

          .select2 {
            font-size: 1.2rem !important;

            .select2-selection--single {
              height: 2.7rem;
              border: solid 1px #ddd;
              width: 27px;

              .select2-selection__rendered {
                line-height: 2;
                font-size: 1.3rem;
              }

              .select2-selection__arrow {
                width: 1.4rem;
                height: 27px;
                display: block;
                position: absolute;
                top: -2px;
                font-size: 1.3rem;
                font-weight: 600;
                text-align: center;
                line-height: 2.2;
                left: 9rem;
                border: none;
              }
            }
          }
        }
      }
    }

    &:after {
      @include clearfix;
    }

    .view-content {
      @include breakpoint($md) {
        width: 62%;
      }

      @include breakpoint($lg) {
        width: 68%;
      }

      min-height: 30rem;

      .views-row {
        margin-bottom: 4rem;
      }

      .views-field-link {
        a {
          color: #000;
          //.glyphicon {
          //  color: $wcm-dark-orange;
          //  font-size: 1.2rem;
          //}
        }

        //.external-link {
        //  &:after {
        //    display: none;
        //  }
        //}
        //h5 a:after {
        //  display: none;
        //}
      }

      .views-field-short-desc {
        border-bottom: 1px solid #ddd;
        padding: 1rem 0px;

        a.e-resource-details-toggle {
          font-size: 1.4rem;
          white-space: nowrap;
        }

        a.e-resource-details-info {
          position: relative;
          top: 1px;
          left: 1px;
        }
      }

      .e-resource-details-wrapper {
        padding: 1rem 0px;
        word-wrap: break-word;

        .e-resource-details {
          font-size: 13px;

          p {
            margin: 0px;

            >span:first-child {
              font-weight: 600;
            }
          }

          a {
            color: $wcm-bright-orange;
          }
        }

        .e-resource-details-hidden {
          display: none;
        }

        a.wcmc-library-tooltip {
          font-size: 1.6rem;
        }
      }
    }

    .item-list {
      clear: both;
    }

    .view-empty {
      min-height: 30rem;
    }
  }

  .view-display-id-panel_pane_2,
  .view-display-id-panel_pane_3 {
    .view-content {
      width: 100%;

      .views-field-short-desc {
        a.e-resource-details-info {
          position: relative;
          top: 1px;
          padding-left: 3px;
        }
      }
    }
  }

  // special case for advanced search
  .ebooks-advanced-search {
    display: block;
    text-align: left;
    clear: both;
    position: relative;
    top: -1.5rem;
  }
}

.information-column {
  .pane-news-panel-pane-7 {
    width: 220px;
    margin: 0 auto 20px;
    text-align: center;

    @include breakpoint($md) {
      width: 245px;
    }

    @include breakpoint($lg) {
      width: 255px;
    }

    .pane-title {
      color: $wcm-black;
      font-size: 1.6rem;
      margin: 3rem 0px 2rem;
    }

    .view-teaser {
      padding: 0px;
      font-size: 1.4rem;
      margin: 2rem 2rem;
      border-bottom: 1px solid #ddd;

      a {
        color: $wcm-dark-orange;
      }
    }
  }

  .pane-profiles-panel-pane-3 {
    width: 220px;
    margin: 0 auto 20px;

    @include breakpoint($md) {
      width: 245px;
    }

    @include breakpoint($lg) {
      width: 255px;
    }

    .pane-title {
      color: $wcm-black;
      font-size: 1.6rem;
      margin: 3rem 0px 2rem;
    }

    .views-row {
      margin: 2rem 0px 1rem;
      clear: both;

      .views-field-field-profile-image {
        float: left;
        width: 32%;
        margin: 0.8rem 1.2rem 0rem 0rem;

        img {
          max-width: 100%;
        }
      }

      .views-field-nothing {
        float: left;
        width: 62%;
        font-size: 1.5rem;
        line-height: 24px;

        .glyphicon {
          font-size: 11px;
          padding-left: 0.4rem;
          color: $wcm-bright-orange;
        }

        p {
          margin-top: 0.5rem;
        }
      }
    }

    &:after {
      @include clearfix;
    }
  }

  .pane-bundle-text {
    width: 220px;
    margin: 3rem auto;

    @include breakpoint($md) {
      width: 245px;
    }

    @include breakpoint($lg) {
      width: 255px;
    }
  }

  .pane-archives-blog-panel-pane-2 {
    clear: both;
    width: 220px;
    margin: 15.5rem auto 4rem auto;

    @include breakpoint($md) {
      width: 245px;
    }

    @include breakpoint($lg) {
      width: 255px;
    }

    .pane-title {
      color: $wcm-black;
      font-size: 1.6rem;
      margin: 4rem 0px 2rem;
    }

    .faq-list {
      font-size: 1.4rem;

      .element__hide {
        padding: 0;

        p {
          margin: 10px 0;
        }
      }
    }

    a {
      color: $wcm-bright-orange;
    }
  }

  #archives-search-form {
    position: relative;
    width: 220px;
    margin: 0 auto;

    @include breakpoint($md) {
      width: 245px;
    }

    @include breakpoint($lg) {
      width: 255px;
    }

    .form-actions {
      .form-actions-wrapper {
        .cta-icon-search {
          position: absolute;
          color: $wcm-white;
          fill: $wcm-white;
          bottom: 1rem;
          right: 1rem;
          font-size: 1.8rem;
        }

        input[type="submit"] {
          position: absolute;
          height: 3.9rem;
          width: 3.9rem;
          font-size: 2rem;
          background: #e97623 none repeat scroll 0% 0%;
          border: medium none;
          border-radius: 0px 3px 3px 0;
          bottom: 0;
          right: 0;
          margin-right: 0;
        }
      }
    }

    //#edit-keyword {
    //  max-width: 24rem;
    //}
  }

  .pane-ask-reference-question {
    margin: 0 auto 2rem auto;
    width: 220px;

    @include breakpoint($md) {
      width: 245px;
    }

    @include breakpoint($lg) {
      width: 255px;
    }

    .ask-reference-question-link {
      text-align: center;
      border-bottom: 1px solid $wcm-border-gray;
      padding: 0 0 0.8rem 0;

      a {
        .wcm-cta__archive-sidebar {
          @include lib-translate(0%, 0%);

          .cta-icon {
            margin-left: -1.4rem !important;
            font-size: 3.8rem;
            color: #b31b1b;
            fill: #e97623;

            &:hover {
              text-decoration: none !important;
            }
          }

          >span {
            position: relative;
            bottom: 12px;
            left: 8px;
            font-size: 14px;
            color: #b31b1b;

            &:hover {
              text-decoration: underline !important;
            }
          }
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .pane-fieldable-panels-pane {
    .btn-access-its {
      letter-spacing: -0.5px;
      width: 23rem;
    }
  }
}

#wcm-cta-need-help {
  .wcm-cta-header {
    font-weight: 600;
    font-size: 1.6rem;
    text-align: center;
    margin-bottom: 1.6rem;
  }
}

#followus-social-media {
  text-align: center;
  width: 220px;
  margin: 3rem auto;

  @include breakpoint($md) {
    width: 245px;
  }

  @include breakpoint($lg) {
    width: 255px;
  }

  >p {
    font-weight: 600;
    font-size: 1.6rem;
  }

  .footer-contact__social-links {
    height: 75px;
    padding: 0;
    float: none;

    li a {
      &:hover {
        color: $wcm-dark-orange;
      }
    }
  }

  &:after {
    clear: both;
  }
}

.ctools-collapsible-processed .ctools-collapsible-handle h2 {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: #cf4520;
  border: 1px solid #ddd;
  border-width: 3px 0 0;
  cursor: pointer;
  margin: 0;
  padding: 15px 55px 15px 10px;
  font-weight: 600;
}

.ctools-collapsible-processed .ctools-collapsible-handle h2:after {
  content: '-';
  width: 50px;
  display: block;
  position: absolute;
  top: 15px;
  right: 0;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  border-left: 3px solid #ddd;
}

.ctools-collapsible-processed.ctools-collapsed .ctools-collapsible-handle h2:after {
  content: '+';
}

.ctools-collapsible-container .ctools-toggle {
  display: none;
}

.ctools-collapsible-content {
  padding-left: 15px;
}
