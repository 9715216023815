
/* =Select 2 Dropdowns Overwrite
----------------------------------------------------------*/
.e-resource-toggle-content {
  .form-item label {
    display: block;
  }
  .form-select {
    max-width: 100%;
  }
  .form-control {
    height: 35px;
    padding: 0;
    border: none;
    width: 100%;
  }
  .select2-container {
    display: none;
  }
  .chosen-container-single {
    .chosen-single {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.428571429;
      color: #555;
      vertical-align: middle;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
      box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
      -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      background-image:none;
    }
    .chosen-single div {
      top:4px;
      color:#000;
    }
  }
  .chosen-container {
    .chosen-drop {
      background-color: #FFF;
      border: 1px solid #CCC;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-clip: padding-box;
      margin: 2px 0 0;
    }
    .chosen-search input[type=text] {
      display: block;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.428571429;
      color: #555;
      vertical-align: middle;
      background-color: #FFF;
      border: 1px solid #CCC;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      background-image:none;
    }
    .chosen-results {
      margin: 0;
      padding: 5px 0 !important;
      font-size: 12px;
      list-style: none;
      background-color: #fff;
      margin-bottom: 5px;
      li, li.active-result {
        display: block;
        padding: 0.3rem 0.8rem;
        clear: both;
        font-weight: normal;
        line-height: 1.428571429;
        color: #333;
        white-space: nowrap;
        background-image:none;
      }
      li:hover,
      li.active-result:hover,
      li.highlighted {
        color: #FFF;
        text-decoration: none;
        background-color: #428BCA;
        background-image:none;
      }
    }
  }
  .chosen-container-multi {
    position: relative;
    .chosen-choices {
      display: block;
      position: relative;
      width: 100%;
      min-height: 34px;
      font-size: 14px;
      line-height: 1.428571429;
      color: #555;
      vertical-align: middle;
      background-color: #FFF;
      border: 1px solid #CCC;
      border-radius: 4px;
      background-image: none;
      padding: 0.4rem 5rem 0.4rem 0.4rem;
      z-index: 0;
      overflow: hidden;
      //-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      //box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      //-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      //transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      li.search-field {
        margin: 0.4rem 0 0 0;
      }
      li.search-field input[type="text"] {
        height: auto;
        font-size: 14px;
        padding: 4px 6px;
      }
      li.search-choice {
        background-image: none;
        padding: 3px 24px 3px 5px;
        font-weight: normal;
        line-height: 1.428571429;
        //text-align: center;
        //white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333;
        background-color: #FFF;
        font-size: 12px;
        margin: 2px 2px;
        .search-choice-close {
          top: 8px;
          right: 6px;
        }
      }
      &:after {
        content: '\e80f';
        position: absolute;
        color: #cf4520;
        font-family: "fontello";
        margin: 0rem 1rem;
        padding: 1.1rem 0.8rem 1.1rem 1.4rem;
        cursor: pointer;
        background-color: #FFF;
        top: 50%;
        right: -1%;
        @include lib-translate(0, -50%);
      }
    }
    &:after {
      content: ' ';
      position: absolute;
      border-left: 1px solid #ccc;
      left: 21rem;
      top: 8.5%;
      height: 84%;
    }
    .chosen-results li.result-selected{
      display: list-item;
      color: #ccc;
      cursor: default;
      background-color: white;
    }
  }
  .chosen-container-multi .chosen-choices li.search-choice
  .chosen-container-multi .chosen-container-active .chosen-choices,
  .chosen-container .chosen-container-single.chosen-container-active .chosen-single,
  .chosen-container .chosen-search input[type=text]:focus{
    border-color: #66AFE9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),0 0 8px rgba(102, 175, 233, 0.6);
  }
}

#views-exposed-form-news-panel-pane-2,
#views-exposed-form-profiles-panel-pane-2 {
  .select2 {
    min-width: 32rem;
  }
}

#views-exposed-form-archives-blog-panel-pane-1 {
  .select2 {
    min-width: 36rem;
  }
}