html.no-js {
  .site-main {
    .info-row-2-wrapper {
      display: none;
    }
  }
  #main-content {
    #access-privileges-pane {
      .form-item-affiliation-affiliated {
        display: none;
      }
      .form-item-other-affiliation-name {
        display: none;
      }
    }
    form#views-exposed-form-wcmc-library-query-panel-pane-1,
    form#e-resources-filter-form {
      display: none;
    }
    .pane-e-resources-alpha {
      .e-resources-alpha-search-wrapper {
        display: none;
      }
    }
    .pane-wcmc-library-query-panel-pane-2,
    .pane-wcmc-library-query-panel-pane-3 {
      .view-id-wcmc_library_query {
        .e-resource-details-wrapper {
          display: block !important;
          visibility: visible !important;
        }
        .views-field-short-desc {
          .e-resource-details-toggle {
            display: none;
          }
        }
      }
    }
  }
}