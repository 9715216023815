.item-list {
  .facetapi-facetapi-checkbox-links {
    list-style: none;
    padding-left: 0;

    li {
      margin-left: 0;

      .facetapi-checkbox {
        margin-right: 4px;
      }
    }
  }
}

.view-scientific-software-search-api,
.view-scientific-software-search,
.view-scientific-software-index-search {
  .views-row {
    padding: 16px;
  }

  .views-row-odd {
    background-color: $wcm-bg-gray;
  }

  h3 {
    margin-top: 0;
  }
}

.bef-select-as-checkboxes {
  height: auto;
}

.bef-checkboxes {
  input[type="checkbox"] {
    width: auto;
    height: auto;
    display: inline-block;
  }
}
